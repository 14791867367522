import {
  Box, Grid, Stack, Button, Tooltip, FormGroup, FormControlLabel, FormControl, Checkbox, Select, 
  Container, List, ListItemText, ListItemButton, Divider, TextField, InputLabel, MenuItem
} from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../App';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Calendar, DatePicker, DateObject } from "react-multi-date-picker";

//********************************************************************************************************
let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
let weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
let sortOrder = 1;

//********************************************************************************************************
function Staff(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  ctx.schedule.staff ||= [];

  let [staff, setStaff] = useState(ctx.schedule.staff);
  let [selectedIndex, setSelectedIndex] = useState(ctx.schedule.staff.length>0? 0 :-1);

  const panelDetails = {
    shortName: "", fullName: "", providerType:"", role:"", cellPhone: "", officePhone: "", homePhone: "", pagerAppPIN: "", email: "",employeeID:"",providerNo:"",
    Monday: true, Tuesday: true, Wednesday: true, Thursday: true, Friday: true, Saturday: true, Sunday: true,
    vacations:[], canDo:{}, frequency: "", howToCall:"", notes:"", 
  }

  //======================================================
  function handleChange(event) {
    if (selectedIndex < 0) return;
    if (Array.isArray(event)) {
      staff[selectedIndex].vacations = event;
    } else if (event.preventDefault) {
      event.preventDefault();
      try {
        let obj = staff[selectedIndex];
        if (event.target.type === "checkbox") {
          obj[event.target.name] = !!! obj[event.target.name];
        } else obj[event.target.name] = event.target.value;
      } catch (err) { }
    }
    ctx.schedule.staff = [...staff];
    setStaff(ctx.schedule.staff);
  }
  //======================================================
  function handleCanDo(event) {
    if (selectedIndex < 0) return;
    if (event.target.type !== "checkbox") return;
    event.preventDefault();
    try {
      let obj = staff[selectedIndex];
      let canDo = {}; ctx.schedule.activities.forEach(item => canDo[item.guid] = false);
      canDo = Object.assign(canDo, obj.canDo);
      canDo[event.target.name] = !!!canDo[event.target.name];
      obj.canDo = canDo;
    } catch (err) { console.log(err.message) }
  
    ctx.schedule.staff = [...staff];
    setStaff(ctx.schedule.staff);
  }
  //======================================================
  function onSortEnd({ oldIndex, newIndex }) {
    setStaff([...ctx.T.moveArrayItem(staff, oldIndex, newIndex)]);
    showDetail(newIndex);
  }
  //======================================================
  async function newStaff() {
    let answer = await ctx.T.prompt("Please enter a name for the new staff person","Add Staff");
    if (ctx.T.isEmpty(answer)) return;
    staff.splice(selectedIndex+1,0, { name: answer, guid: ctx.T.guid()});
    showDetail(selectedIndex+1);
  }
  //======================================================
  async function deleteStaff() {
    if (selectedIndex >= 0) {
      let yes = await ctx.T.confirm(`Do you want to permanently delete "${ staff[selectedIndex].name }" and all associated data ?`);
      if (!yes) return;
      staff.splice(selectedIndex, 1);
      if(staff.length <= 0) selectedIndex = -1;
      setStaff([...staff]);
      showDetail(selectedIndex);
    } else ctx.T.alert("No Item Selected");
  }
  //======================================================
  async function editName() {
    try {
      let answer = await ctx.T.prompt("Staff Name", "Edit Name", staff[selectedIndex].name);
      if (ctx.T.isEmpty(answer)) return;
      staff[selectedIndex].name = answer;
      ctx.schedule.staff = [...staff];
      showDetail(selectedIndex);
    } catch(err){}
  }
  //======================================================
  function sortNames() {
    try {
      staff.sort((a, b) => {
        try {
          let fa = a.name.toLowerCase();
          let fb = b.name.toLowerCase();
          if (fa < fb) return -sortOrder;
          if (fa > fb) return sortOrder;
        } catch(err) {}
        return 0;
      })
      ctx.schedule.staff = [...staff];
      showDetail(selectedIndex);
      sortOrder *= -1;
    } catch(err){}
  }
  //======================================================
  function showDetail(index) {
    try {
      staff[index] = { ...panelDetails, ...staff[index] };
      setSelectedIndex(index);
      setStaff([...staff]);
    } catch (err) { }
  }
  //======================================================
  function staffName() {
    try {
      return staff[selectedIndex].name;
    } catch (err) { }
    return "";
  }
  //======================================================
  function isChecked(checkName) {
    try {
      return !! staff[selectedIndex][checkName];
    } catch (err) { }
    return false;
  }
  //======================================================
  function isCanDo(checkGUID) {
    try {
      return !!staff[selectedIndex].canDo[checkGUID];
    } catch (err) { }
    return false;
  }
  //======================================================
  function newRow(label, dataSlug, minRows = 0, label2 = false, dataSlug2 = false) {
    return (<>
      <Grid item xs={3}>{label}</Grid>
      <Grid item xs={label2===false?9:3}><TextField variant="outlined" size="small" multiline={minRows > 0 ? true : false} minRows={minRows}
        name={dataSlug} value={staff[selectedIndex][dataSlug]}
        sx={{ width: "100%" }} onChange={handleChange}/>
      </Grid>
      {label2 && <>
        <Grid item xs={dataSlug2===false?6:2} sx={{textAlign:'right',pr:0.5}}>{label2}</Grid>
        {dataSlug2 && <Grid item xs={4}><TextField variant="outlined" size="small" multiline={minRows > 0 ? true : false} minRows={minRows}
          name={dataSlug2} value={staff[selectedIndex][dataSlug2]}
          sx={{ width: "100%" }} onChange={handleChange} />
        </Grid>}
      </>}
    </>)
  }
  //======================================================
  useEffect(() => {
    if (selectedIndex < 0) return;
    showDetail(selectedIndex);
    ctx.schedule.staff = staff;
  },[staff.length]);
  //======================================================
  // useEffect(() => {
  //    console.log("Post", selectedIndex, staff[selectedIndex]);
  // });
  //======================================================

  let SortableItem = SortableElement(({ item, selected }) => 
    <>
      <ListItemButton selected={selected} name={item.guid}>  
        <ListItemText primary={item.name} sx={ {p:0,m:0}} />
      </ListItemButton>
      <Divider />
    </>
  );
  let SortableList = SortableContainer(({ items }) => 
    <List dense sx={{ border: 1, maxHeight:600, overflow:'auto'}} >
      {items.map((item, index) => <SortableItem item={item} index={index} onClick={() => showDetail(index)}
        selected={selectedIndex === index} key={index}
      />)}
    </List>
  );
  

  return (<>
    <Box sx={{display:"flex", flexDirection:"row", px:2}}>
      <Box component="h2" sx={{ flexGrow:1}}>Staff List</Box>
    </Box>
    <Stack direction="row" alignItems="flex-start" sx={{p:2}}>
      {selectedIndex >= 0 && <Container sx={{ width: "300px" }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 0, mx:1, fontSize: "1.7rem" }}>
          <Tooltip title="Add New" placement="top">
            <Box component="i" className="fa fa-plus-circle" sx={{ pr: 2, color: "#00A170", cursor: "pointer" }} onClick={newStaff} />
          </Tooltip>
          <Tooltip title="Edit" placement="top">
            <Box component="i" className="fa fa-pencil-square-o" sx={{ pr: 2, color: "#00A170", cursor: "pointer" }} onClick={editName} />
          </Tooltip>
          <Tooltip title="Sort Alphabetically" placement="top">
            <Box component="i" className="fa fa-sort" sx={{ pr: 2, color: "#00A170", cursor: "pointer" }} onClick={sortNames} />
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <Box component="i" className="fa fa-trash" sx={{ color: "red", cursor: "pointer" }} onClick={deleteStaff} />
          </Tooltip>
        </Stack>
        <SortableList items={staff} onSortEnd={onSortEnd} />
      </Container>}
      {selectedIndex < 0 && <Grid justifyContent="center" className="w3-sand" container sx={{p:4 }}>
        <h3>No Staff Information on File.  Click
          <Box component="i" className="fa fa-plus-circle" sx={{ mx: 1, fontSize: "2rem", color: "#00A170", cursor: "pointer" }} onClick={newStaff} /> to add
        </h3>
      </Grid>}
      {selectedIndex >= 0 &&<Grid container spacing={1} sx={{ border: 1, pr: "10px" }}>
        <Grid item xs={3} sx={{mt:0.5}}>Staff Person</Grid>
          <Grid item xs={2} sx={{mt:0.5,color:"blue",fontWeight:'bold'}}>{staffName()}</Grid>
          <Grid item xs={2} sx={{textAlign:'right',pr:0.5,mt:0.5}}>Credentials</Grid>
          <Grid item xs={2}><FormControl fullWidth>
            <Select name="providerType" value={staff[selectedIndex].providerType || ""} variant="standard" onChange={handleChange}>
              <MenuItem value="">&nbsp;</MenuItem>
              <MenuItem value="MD">MD</MenuItem>
              <MenuItem value="MD PhD">MD PhD</MenuItem>
              <MenuItem value="DO">DO</MenuItem>
              <MenuItem value="NP">DPM</MenuItem>
              <MenuItem value="NP">NP</MenuItem>
              <MenuItem value="PA">PA</MenuItem>
              <MenuItem value="CRNA">CRNA</MenuItem>
            </Select>
          </FormControl></Grid>
          <Grid item xs={1} sx={{textAlign:'right',pr:0.5,mt:0.5}}>Role</Grid>
          <Grid item xs={2}><FormControl fullWidth>
            <Select name="role" value={staff[selectedIndex].role || ""} variant="standard" onChange={handleChange}>
              <MenuItem value="">&nbsp;</MenuItem>
              <MenuItem value="Attending">Attending</MenuItem>
              <MenuItem value="Fellow">Fellow</MenuItem>
              <MenuItem value="Resident">Resident</MenuItem>
              <MenuItem value="NP">NP</MenuItem>
              <MenuItem value="PA">PA</MenuItem>
            </Select>
          </FormControl></Grid>

          {newRow("Initials / Short Name", "shortName")}
          {newRow("Full Name", "fullName")}
          {newRow("Employee ID", "employeeID", 0, "Provider No", "providerNo")}
          {newRow("E-mail", "email")}
          {newRow("Cell Phone", "cellPhone", 0, "Office Phone", "officePhone")}
          {newRow("PagerApp PIN", "pagerAppPIN", 0, "Home Phone", "homePhone")}
          <Grid item xs={3}>Availability</Grid>
          <Grid item xs={9}>
            <FormGroup row sx={{ width: "90%", borderTop: "1px solid silver" }}>
              {weekDays.map((day, index) => <FormControlLabel control={<Checkbox value={true} checked={isChecked(day)} name={day} onClick={handleChange} />} key={day} label={day} />)}
            </FormGroup>
          </Grid>

          <Grid item xs={3}>Vacations</Grid>
          <Grid item xs={9}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", padding: "1rem", width: "90%", borderTop: "1px solid silver" }}>
              <Calendar
                numberOfMonths={2}
                multiple
                disableMonthPicker
                currentDate={new DateObject(new Date())}
                value={staff[selectedIndex].vacations}
                onChange={handleChange}
              />
            </Box>
          </Grid>
          {newRow("How to Call", "howToCall", 3)}
          {newRow("Notes", "notes", 3)}
          <Grid item xs={12}>&nbsp;</Grid>
      </Grid>}
      {selectedIndex >= 0 && <Grid container spacing={1} sx={{ width: "290px", ml: 1, pl: 2, border: 1 }}>
        <FormGroup >
          <h4>Duties</h4>
          {ctx.schedule.activities.map((activity, index) => <FormControlLabel control={<Checkbox value={true} color="success"
            checked={isCanDo(activity.guid)}
            name={activity.guid} onClick={handleCanDo} />} key={activity.guid} label={activity.name} />)}
        </FormGroup>
      </Grid>}
    </Stack>
    <p /> 
  </>)
}

Staff.defaultProps = {
  bkColor: "#008ae6",
  company: " AMC Logic, LTD"
}

export default Staff;
