import { useState, useContext } from 'react';
import { useLocation} from 'react-router-dom';
import { GlobalContext } from '../App';
import MonthGrid from './MonthGrid';

//********************************************************************************************************
function Month(props) {
  let [ctx, setContext] = useContext(GlobalContext);
  if (typeof ctx.schedule.assignments !== "object") ctx.schedule.assignments = {};
  if (typeof ctx.schedule.info !== "object") ctx.schedule.info = {};
  if (!Array.isArray(ctx.schedule.staff)) ctx.schedule.staff = [];
  if (!Array.isArray(ctx.schedule.activities)) ctx.schedule.activities = [];
  if (typeof ctx.schedule.notes !== "object") ctx.schedule.notes = {};

  let [state, setState] = useState({ month: (new Date().getMonth()), grid: "monthly", condensed: false, edit: false });
  let location = useLocation();
  try { props = { ...props, ...location.state } } catch (err) { }
  
  //======================================================
  function getRowHeaders() {
    let ar = ctx.schedule.activities.map(act => act.name);
    ar.push("Vacations");
    return ar;
  }
  //======================================================
  function createContent(editable) {
    let objContent = {};
    for (let act of ctx.schedule.activities) {
      for (let doy = 1; doy <= 366; doy++) {
        try {
          let p = ctx.U.personFromGUID(ctx.schedule.assignments[act.guid][doy]);
          objContent[act.name + "-" + doy] = state.condensed? p.shortName : p.name;
        } catch(err) {objContent[act.name + "-" + doy] = ""}
      }
    }
    // Add vacations
    for (let doy = 1; doy <= 366; doy++) {
      objContent["Vacations-" + doy] = <ctx.U.VacationPopup away={ctx.schedule.vacations[doy] ??= []} doy={doy} />
    }
    return objContent;
  }
  //======================================================

   return (
    <MonthGrid 
      rowHeaders={getRowHeaders()}
      condensed={state.condensed}
      edit={false}
      holidays={ctx.U.calcHolidays()}
      content={createContent(state.edit,state.condensed)}
    />)
}
Month.defaultProps = {
  bkColor: "#008ae6",
  company: " AMC Logic, LTD"
}

export default Month;