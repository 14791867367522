import { useContext, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Redirect, useLocation, useNavigate, Link } from 'react-router-dom';
import { GlobalContext } from '../App';
import { Box, Stack, Button } from '@mui/material'
import Topbar from './Topbar.js';
import Footer from './Footer'
import Login from './Login.js';
import Home from './Home.js';
import Month from './Month.js';
import Schedules from './Schedules.js';
import Activity from './Activity.js';
import Staff from './Staff.js';
import BasicInfo from './BasicInfo.js';
import User from './User.js';
import Admin from './Admin.js';
import View from './View.js';
import util from './Util.js';
import university from '../images/university.png';

const welcome = <><h2>WELCOME</h2>
  <p>Call Schedule, your source for easy online call schedule creation, display and delivery at internet speed. </p></>;

const introRegister = <><h2>INFORMATION</h2>
  <p>Please create an account.  In order to protect your privacy, we request only the minimum necessary information.
  A temporary password will be emailed to you at your supplied email. Your email is your User ID.  </p></>;

const intro = { Login: welcome, Register: introRegister, Forgot: "", Reset: "" };
const initialMenu = [
  { icon: "home", click: () => {}, path: "/", title: "Home" },
  { icon: "bullhorn", click: () => { }, path: "/view", title: "View Published Schedule" },
  { icon: "sign-in", click: () => { }, path: "/login", title: "Sign-in" },
  { icon: "commenting", click: () => {}, path: "/about", title: "About Us" },
];
const publicMenu = [
];
const signedinMenu = [
  { icon: "home", click: () => {}, path: "/", title: "Home" },
  { icon: "bullhorn", click: () => { }, path: "/view", title: "View Published Schedule" },
  { icon: "folder-open", click: () => {}, path: "/schedules", title: "List of Schedules" },
  { icon: "gear", click: () => {}, path: "/user", title: "User Information" },
  // { icon: "commenting", click: () => {}, path: "/about", title: "About Us" },
  { icon: "sign-out", click: () => {}, path: "/logout", title: "Sign-out" },
];
const paidMenu = [
  { icon: "home", click: () => {}, path: "/", title: "Home" },
  { icon: "bullhorn", click: () => { }, path: "/view", title: "View Published Schedule" },
  { icon: "folder-open", click: () => {}, path: "/schedules", title: "List of Schedules" },
  { icon: "calendar", click: () => {}, path: "/month", title: "View Schedule" },
  { icon: "info-circle", click: () => {}, path: "/info", title: "General Infomation" },
  { icon: "list", click: () => {}, path: "/activities", title: "Duties" },
  { icon: "user", click: () => {}, path: "/staff", title: "Staff/Teams" },
  { icon: "gear", click: () => {}, path: "/user", title: "User Information" },
  // { icon: "commenting", click: () => {}, path: "/about", title: "About Us" },
  { icon: "sign-out", click: () => {}, path: "/logout", title: "Sign-out" },
];
const editMenu = [
  { icon: "home", click: () => {}, path: "/", title: "Home" },
  { icon: "bullhorn", click: () => { }, path: "/view", title: "View Published Schedule" },
  { icon: "folder-open", click: () => {}, path: "/schedules", title: "List of Schedules" },
  { icon: "calendar", click: () => {}, path: "/month", title: "View Schedule" },
  { icon: "info-circle", click: () => {}, path: "/info", title: "General Infomation" },
  { icon: "list", click: () => {}, path: "/activities", title: "Duties" },
  { icon: "user", click: () => {}, path: "/staff", title: "Staff/Teams" },
  { icon: "gear", click: () => {}, path: "/user", title: "User Information" },
  // { icon: "commenting", click: () => {}, path: "/about", title: "About Us" },
  { icon: "sign-out", click: () => {}, path: "/logout", title: "Sign-out" },
];
let toolbarLocations = ["/info","/month","/activities","/staff"];
//********************************************************************************************************
function Site(props) {
  let location = useLocation();
  let navigate = useNavigate();
  let [menu, setMenu] = useState(initialMenu);
  let [ctx, setContext] = useContext(GlobalContext);
  ctx.U = util(ctx);
  const theme = useTheme(); 

  //======================================================
  function autoLogout (minutes=420) {
    var idleTime = 0;
    var idleInterval = setInterval(timerIncrement, 60000); // 1 minute

    // Zero the idle timer on mouse movement.
    document.addEventListener("mousemove",function (e) { idleTime = 0;});
    document.addEventListener("keypress",function (e) { idleTime = 0;});

    function timerIncrement() {
      idleTime = idleTime + 1;
      if (idleTime > minutes) {
        navigate("/");
        window.location.reload();
      }
    }
  }
  //======================================================
  useEffect(() => {     // set Menu
    let menu = initialMenu;
    
    switch (location.pathname) {
      case "/view": menu = publicMenu; break;
      // case "/schedules":
      // case "/activities":
      // case "/staff":
      //   menu = editMenu; break;
      default: menu = initialMenu; 
    }
    try {
      if (ctx.T.user._id.length > 10) menu = signedinMenu;
      if (ctx.T.user.paid) menu = paidMenu;
      if (!ctx.T.isEmpty(ctx.schedule)) menu = editMenu;
    } catch (err) { }

    setMenu(menu);
  });
  //======================================================
  useEffect(()=>autoLogout(), []);
  //======================================================

  let loggedIn = false, expired = true;
  try { loggedIn = ctx.T.user._id.length > 10; } catch (err) { }
  try { expired = ctx.T.user.expired; } catch (err) { }

  return (<>
    <Topbar title="Call Schedule" color={theme.palette.primary.main} image={university} user={ctx.T.user.firstName} />
    <Box display="flex" flexDirection="row" alignContent="stretch">
      <Box className={ctx.bkSidebar} style={{ minHeight: window.outerHeight, position: "fixed", width: "60px"}}>
        <Box sx={{ textAlign: 'center'}} >
          {menu.map((item, nn) => <Link to={item.path} title={item.title} key={item.path} >
            <i className={`fa fa-${ item.icon } menuButton ${ location.pathname === item.path ? "menuSelected" : "" }`}></i>
          </Link>)}
        </Box>
      </Box>
      <Box flexGrow={1} mb={10} style={{ minHeight: window.outerHeight, marginLeft: "60px" }}>
        {toolbarLocations.indexOf(location.pathname) >= 0 && <Box className={ctx.bkSidebar} sx={{px:2}}>
          <Stack direction="row" justifyContent="space-between">
            <Box sx={{ flexGrow:1}}>&nbsp;</Box>
            <Stack direction="row" sx={{ py: 1 }} alignItems="flex-start" justifyContent="flex-end" spacing={2}>
              <Button variant="contained" onClick={ctx.U.report}>Reports</Button>
              <Button variant="contained" onClick={ctx.U.publish}>Save & Publish</Button>
              <Button variant="contained" onClick={ctx.U.saveSchedule}>Save</Button>
            </Stack>
          </Stack>
        </Box>}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={
            <Login company="AMC Logic, LTD" image={university} intro={intro} terms="terms.pdf" bkColor={theme.palette.primary.main} />
          }></Route>
          <Route exact path="/logout" element={
            <Login company="AMC Logic, LTD" image={university} intro={intro} terms="terms.pdf" bkColor={theme.palette.primary.main} />
          }></Route>
          <Route exact path="/month" element={<Month />} />
          <Route exact path="/schedules" element={<Schedules/>} />
          <Route exact path="/info" element={<BasicInfo/>} />
          <Route exact path="/activities" element={<Activity/>} />
          <Route exact path="/staff" element={<Staff/>} />
          <Route exact path="/user" element={<User />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/view" element={<View/>} />
          <Route exact path="/view/:accountNo/:slug" element={<View/>} />
          <Route exact path="/view/:accountNo/:slug/:securityCode" element={<View/>} />
          {/* 
          {!loggedIn? <Redirect to="/login" /> : null}
          <Route exact path="/payment" component={Payment} />
          {ctx.T.paymentExpired ? <Redirect to="/payment" /> : null}
          */}
        </Routes>
      </Box>
    </Box>
    <Footer />


    <style>{`
      .menuButton {
        color: ${theme.palette.primary.main};
        font-size: 22pt;
        width: 50px;
        height: 40px;
        margin-top: 0.3rem;
        margin-left: 2px;
        margin-right: 2px;
        padding-top: 0.3rem;
        cursor: pointer;
        border-radius: 0.3rem;
      }
      .menuButton:hover {
        background-color: ${theme.palette.primary.main};
        color: white;
      }
      .menuSelected {
        background-color: ${theme.palette.primary.main};
        color: white;
      }
    `}</style>
  </>)
}
//********************************************************************************************************

Site.defaultProps = {
}

export default Site;